<template>
  <div>
    <div @click.prevent="toggleModal" class="cursor-pointer hover:bg-gray-100 ">
      <div class="p-3 py-3 flex items-center leading-none space-x-2 ">
        <div class="w-5 flex justify-center">
          <font-awesome-icon
            :icon="['fal', icon]"
            class="text-gray-400 text-lg"
          />
        </div>
        <div>
          {{ label }}
        </div>
      </div>
    </div>
    <modal
      :open="isVisible"
      :on-close="toggleModal"
      :id="key"
      size="xs"
      :modal-title="label"
    >
      <div class="space-y-6">
        <div class="space-y-2" v-if="products.length > 0">
          <div
            class="flex space-x-4 items-center"
            v-for="(product, index) in products"
            :key="product.sku"
          >
            <div class="w-12 h-12 object-contain rounded-lg overflow-hidden">
              <img
                :src="`/images/products/${utils.getImage(product.sku)}.webp`"
              />
            </div>
            <div class="flex-grow">
              <div>
                {{ product.name }}
                <span v-if="product.isKitten">(Kitten)</span>
                <span v-if="!product.hasDryFood && isTaster">(No Dry)</span>
              </div>
              <div class="font-mono text-xs mt-1 text-gray-400">
                {{ product.sku }}
              </div>
            </div>
            <div class="font-mono text-xs mt-1 text-gray-400">QTY</div>
            <div class="w-20">
              <input type="number" v-model.number="product.quantity" />
            </div>
            <div @click="products.splice(index, 1)">
              <font-awesome-icon
                :icon="['fal', 'trash-can']"
                class="text-gray-400 text-lg hover:text-red-400 cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="p-4 font-mono h-40 flex flex-col space-y-4 justify-center items-center rounded-lg mb-4 text-center text-gray-400"
        >
          <font-awesome-icon
            :icon="['fal', 'box-open']"
            class="text-gray-400 text-2xl "
          />
          <div class="text-sm">No items in the order, why not add some!</div>
        </div>
        <div class="bg-gray-100 rounded-lg">
          <add-products-btn
            @add-product="addProduct"
            :active-products="products"
            :is-taster="isTaster"
          />
        </div>
        <div class="border-t space-y-4 pt-4 ">
          <div class="text-gray-600">
            This will create a new order in the packing list. The customer will
            not be charged.
            <span v-if="order"
              >Please check all quantities and amend where needed.</span
            >
          </div>
          <div v-if="!isTaster" class="flex space-x-4 items-center">
            <!-- create a checkbox fo decide if it should go next day or not defaulted to false -->
            <label
              class="flex items-center space-x-2 p-2 rounded bg-gray-100 px-3"
            >
              <input type="checkbox" v-model="isNextDay" />
              <div class="text-gray-500  ">
                Send as
                <span class="font-medium text-gray-900">Next Day</span> delivery
              </div>
            </label>
          </div>
          <div class="flex space-x-4">
            <form @submit.prevent="createOrder" class="space-y-4">
              <loading-button
                :is-loading="isLoading"
                :is-enabled="products.length > 0"
                >Create order</loading-button
              >
            </form>
            <form @submit.prevent="toggleModal">
              <loading-button secondary>Cancel</loading-button>
            </form>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import { format } from 'date-fns';
import utils from '@/utils';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash/fp';

import AddProductsBtn from './AddProductsBtn.vue';

export default {
  name: 'CreateOrder',
  components: {
    Modal,
    LoadingButton,
    AddProductsBtn
  },
  props: {
    label: {
      type: String,
      required: 'Create order'
    },
    order: {
      type: Array
    },
    isTaster: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'clone'
    }
  },
  data() {
    return {
      isLoading: false,
      key: utils.createKey('order-create', 6),
      isVisible: false,
      products: [],
      startingOrder: [],
      utils,
      delivery: 'dpd_2^12_free',
      isNextDay: false
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      createNewOrder: 'createOrder',
      formatOrder: 'formatOrder'
    }),
    toggleModal() {
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        const clone = cloneDeep(this.order);

        if (this.order) {
          this.products = this.isTaster
            ? utils.getTastersFromCats(clone)
            : utils.getProductsFromCats(clone);
        }
      } else {
        this.products = [];
        this.startingOrder = [];
      }
    },
    createOrder() {
      this.isLoading = true;
      const data = {
        uuid: this.$route.params.id,
        data: {
          items: utils.formatCreateOrder(this.products, this.isTaster),
          delivery: this.isNextDay ? 'dpd_2^12_199' : 'dpd_2^12_free',
          dispatchAt: format(new Date(), 'yyyy-MM-dd')
        },
        isTaster: this.isTaster
      };

      this.createNewOrder(data).then(() => {
        if (this.$route.params.tab === 'activity') {
          this.$store.dispatch('getUserActivity', this.$route.params.id);
        }
        this.$store
          .dispatch('getUserHistory', this.$route.params.id)
          .then(() => {
            this.isLoading = false;
            this.toggleModal();
          });
      });
    },
    addProduct(product) {
      let item = {};
      if (this.isTaster) {
        item = { ...product, quantity: 1 };
      } else {
        item = {
          ...product,
          quantity: product.foodtype === 'dry' ? 1 : 5
        };
      }

      this.products.push(item);
    }
  },
  mounted() {}
};
</script>

<style></style>
